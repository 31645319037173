import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import BannerKentei from '../components/BannerKentei'
import { StaticImage } from "gatsby-plugin-image"

import kentei06 from '../assets/images/kentei06.png'
import kentei06ura from '../assets/images/kentei06ura.png'


const Kentei = (props) => (
    <Layout>
        <Helmet>
            <title>都市計画ビジュアルコミュニケーター検定</title>
            <meta name="description" content="検定の概要" />
        </Helmet>

        <BannerKentei />

        <div id="main">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h2>検定試験実施の目的</h2>
                    </header>
                    <p>　近年、人口減少、高齢化が急激に進行し、地方財政、地域経済がより厳しさを増している中、今後のまちづくりを考える上では医療、福祉など市民生活を支える都市サービスの機能の維持、地域経済、地方財政の健全性の確保など、これまでの都市計画の領域を超えた視点、取組が必要になってきます。
                        <br/>
                    　このような状況の中で、よりよいまちづくりを推進していくためには多様なデータの活用等により、都市の現状や将来の都市構造を分析し、客観的、専門的立場からまちの課題や対応方針を示すとともに、地域の合意形成に取り組むことができる人材の育成が不可欠です。<br/>
                    　そこで都市構造可視化推進機構では、都市計画に携わる技術者に対して、都市構造可視化技術の普及促進を図り、都市構造に対する理解を促進し、地方都市における集約型都市構造の実現に資することを目的として、都市計画ビジュアルコミュニケーター検定試験を実施し、都市計画実務者が理解しておくべき、都市の現状や将来の都市構造を分析するスキル、知識の普及に努めています。
                    </p>
                </div>
            </section>
            <section id="two" className="spotlights">
                <section>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>第８回検定のお知らせ（2024年2月7日実施）</h3>
                            </header>
                            <p>第８回検定は、2024年2月7日に開催されます。<br/>
                            下記より資料がダウンロードできます。<br/>
                            ご確認の上、お申し込みください。<br/></p>
                            <ul className="actions">
                                <li><Link to="/kentei08_panph.pdf" className="button">検定パンフレット（A4表裏 PDFファイル）</Link></li>
                            </ul>
                            <ul className="actions">
                                <li><Link to="/kentei08.pdf" className="button">検定の実施について</Link></li>
                                <li><Link to="/kentei08.xlsx" className="button">申込書</Link></li>
                            </ul>
                            <p>※ 団体受験制度もあります。<br/>　団体受験は、別日程となります。随時お問い合わせください。</p>
                        </div>
                    </div>
                    <Link to="/kentei08_panph.pdf" className="image">
                        <StaticImage src="../images/kentei08_pamph.png" alt="imgae" />
                    </Link>

                </section>
                <section>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>検定テキストについて<span style={{color:"red"}}>　2023年版好評発売中</span></h3>
                            </header>
                            <p>検定テキストでは、都市計画ビジュアルコミュニケーター検定試験合格に必要となる統計、都市計画、都市構造可視化技術をわかりやすく整理しています。受験者の皆さまが本書を有効に活用されて、都市計画ビジュアルコミュニケーター検定試験に合格し、都市構造可視化技術のエキスパートとして活躍されることを願っております。<span style={{color:"red"}}></span></p>
                            <ul className="actions">
                                <li><a href="https://www.amazon.co.jp/s?i=stripbooks&rh=p_27%3A%E4%B8%80%E8%88%AC%E7%A4%BE%E5%9B%A3%E6%B3%95%E4%BA%BA%E9%83%BD%E5%B8%82%E6%A7%8B%E9%80%A0%E5%8F%AF%E8%A6%96%E5%8C%96%E6%8E%A8%E9%80%B2%E6%A9%9F%E6%A7%8B&s=relevancerank&text=%E4%B8%80%E8%88%AC%E7%A4%BE%E5%9B%A3%E6%B3%95%E4%BA%BA%E9%83%BD%E5%B8%82%E6%A7%8B%E9%80%A0%E5%8F%AF%E8%A6%96%E5%8C%96%E6%8E%A8%E9%80%B2%E6%A9%9F%E6%A7%8B&ref=dp_byline_sr_book_1" className="button">外部リンク：テキスト等販売サイト</a></li>
                            </ul>
                        </div>
                    </div>
                    <a href="https://www.amazon.co.jp/s?i=stripbooks&rh=p_27%3A%E4%B8%80%E8%88%AC%E7%A4%BE%E5%9B%A3%E6%B3%95%E4%BA%BA%E9%83%BD%E5%B8%82%E6%A7%8B%E9%80%A0%E5%8F%AF%E8%A6%96%E5%8C%96%E6%8E%A8%E9%80%B2%E6%A9%9F%E6%A7%8B&s=relevancerank&text=%E4%B8%80%E8%88%AC%E7%A4%BE%E5%9B%A3%E6%B3%95%E4%BA%BA%E9%83%BD%E5%B8%82%E6%A7%8B%E9%80%A0%E5%8F%AF%E8%A6%96%E5%8C%96%E6%8E%A8%E9%80%B2%E6%A9%9F%E6%A7%8B&ref=dp_byline_sr_book_1" className="image" style={{display:"flex"}}>
                        <StaticImage src="../images/kenteitext.jpg" alt="imgae" />
                    </a>
                </section>
                {/* <section>
                    <Link to="" className="image">
                        <img src={pic10} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>過去の検定について</h3>
                            </header>
                            <p>この検定は、2018年より行われています。<br/>これまで５回実施されています。</p>
                        </div>
                    </div>
                </section> */}
            </section>
        </div>

    </Layout>
)

export default Kentei