import React from 'react'

const BannerLanding = (props) => (
    <section id="banner" className="style2">
        <div className="inner">
            <header className="major">
                <h1>都市計画ビジュアルコミュニケーター検定</h1>
            </header>
            <div className="content">
                <p>都市計画ビジュアルコミュニケーター検定は、都市構造可視化等を活用した都市計画のコミュニケーション能力についての検定です。<br />
                どなたでも受験できます。</p>
            </div>
        </div>
    </section>
)

export default BannerLanding
